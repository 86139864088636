<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Image Upload Cropper ------------>
      <!------------ START: Drop overlay ------------>
      <div
        v-if="isMounted"
        v-show="$refs.upload && $refs.upload.dropActive && !isDisabled"
        class="drop-active"
      >
        <h3>{{ $t("formHelper.dropFilesToUpload") }}</h3>
      </div>
      <!------------ END: Drop overlay ------------>
      <!------------ START: Image upload ------------>
      <div v-show="!edit" class="image-upload">
        <!------------ START: Dropzone ------------>
        <label v-if="!value.length" for="image" class="d-block">
          <div class="card card-custom shadow-none cursor-pointer mb-3">
            <div v-if="!field.defaultImage" class="card-body text-center">
              <div class="h4 text-muted my-3">
                {{
                  field.dropZoneLabel
                    ? getSnippet(field.dropZoneLabel)
                    : $t("formHelper.imageDropZoneLabel")
                }}
              </div>
            </div>
            <div v-else>
              <img
                id="image"
                :src="field.defaultImage"
                class="img-thumbnail"
                height="100"
                :alt="$t('formHelper.imageAlt')"
              />
            </div>
          </div>
        </label>
        <!------------ END: Dropzone ------------>
        <!------------ START: Image ------------>
        <div v-else class="text-center">
          <img
            :src="value[0].url"
            class="img-thumbnail"
            width="100"
            :alt="$t('formHelper.imageAlt')"
          />
        </div>
        <!------------ END: Image ------------>
        <!------------ START: Upload button ------------>
        <div class="file-upload text-center">
          <file-upload
            v-show="!value.length && !field.defaultImage"
            ref="upload"
            v-model="value"
            :name="fileUploadName"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            class="btn btn-primary"
            :class="{ disabled: isDisabled }"
            :drop="!edit"
            @input="onInput"
            @input-filter="inputFilter"
            @input-file="inputFile"
          >
            {{
              field.uploadLabel
                ? getSnippet(field.uploadLabel)
                : $t("formHelper.imageUploadLabel")
            }}
          </file-upload>
          <button
            v-show="value.length || field.defaultImage"
            type="button"
            class="btn btn-secondary m-2"
            @click.prevent="remove"
          >
            {{ $t("formHelper.imageRemove") }}
          </button>
        </div>
        <!------------ END: Upload button ------------>
      </div>
      <!------------ END: Image upload ------------>
      <!------------ START: Image edit ------------>
      <div v-show="edit" class="image-edit">
        <div v-if="value.length" class="image-edit-image" style="height: 500px">
          <img
            v-if="value[0].url"
            ref="editImage"
            :src="value[0].url"
            :alt="$t('formHelper.imageAlt')"
          />
        </div>
        <div class="text-center mt-3">
          <button
            type="button"
            class="btn btn-secondary m-2"
            @click.prevent="$refs.upload.clear"
          >
            {{ $t("formHelper.cancel") }}
          </button>
          <button
            type="submit"
            class="btn btn-primary m-2"
            @click.prevent="saveEdit"
          >
            {{ $t("formHelper.imageCropLabel") }}
          </button>
        </div>
      </div>
      <!------------ END: Image edit ------------>
      <!------------ END: Image Upload Cropper ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import FileUpload from "vue-upload-component";
import { base } from "@/components/Tools/FormHelper/Helper/mixins";

import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
  components: { FieldWrapper, FileUpload },
  mixins: [base],
  props: {},
  data() {
    return {
      value: this.defaultValue ?? null,
      edit: false,
      cropper: false,
      isMounted: false
    };
  },
  computed: {
    fileUploadName: function () {
      // Return image identifier or "image" as fallback
      return this.identifier ? this.identifier : "image";
    }
  },
  watch: {
    // Observe edit status
    edit(value) {
      // If no value is set, return
      if (!value) {
        // If cropper exists, destroy
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
        return;
      }
      let me = this;
      this.$nextTick().then(() => {
        // If edit image element is not found, return
        if (!me.$refs.editImage) {
          return;
        }
        // Set cropper options
        let cropperOptions = {
          aspectRatio: this.field.ratio ?? NaN,
          viewMode: 0,
          crop: function () {}, //to show the crop box manually
          minCanvasWidth: 50,
          minCanvasHeight: 50,
          minCropBoxWidth: 150,
          minCropBoxHeight: 150,
          minContainerWidth: 150, //decides the size of image
          minContainerHeight: 150, //decides the size of image
          autoCropArea: 1,
          modal: true, // Show the black modal
          guides: true, // Show the dashed lines for guiding
          center: true, // Show the center indicator for guiding
          highlight: true, // Show the white modal to highlight the crop box
          background: false, // Show the grid background,
          scalable: true,
          rotatable: true,
          checkOrientation: true,
          cropBoxResizable: true,
          dragMode: "move"
        };
        // Set new cropper
        me.cropper = new Cropper(me.$refs.editImage, cropperOptions);
      });
    }
  },
  mounted() {
    // Set isMounted flag
    this.isMounted = true;
  },
  methods: {
    // On file input
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // If new image was selected
        let me = this;
        this.$nextTick().then(() => {
          // Activate editing
          me.edit = true;
        });
      } else if (!newFile && oldFile) {
        // If image was deleted, deactivate editing
        this.edit = false;
      }
    },
    // Filter file input
    inputFilter(newFile, oldFile, prevent) {
      if (this.isDisabled) {
        return prevent();
      }
      // If new file is selected and no current file is set
      if (newFile && !oldFile) {
        // Test for valid image extensions
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          // Show error
          this.$swal.fire({
            icon: "error",
            title: this.$t("formHelper.notPictureError")
          });
          // Prevent upload
          return prevent();
        }
      }
      // If new file is different from old value
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Set new file url
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        // Create object url
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    // On edit confirm
    saveEdit() {
      // Deactivate editing
      this.edit = false;
      let oldFile = this.value[0];
      // Create new file object by binary string (copied from docs)
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      // Set new file object
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      // Update elements value to new file
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      });
      // Emit new value
      this.onInput();
    },
    remove() {
      // Remove file and emit value
      this.value = [];
      this.onInput();
    }
  }
};
</script>

<style lang="scss">
.image-upload {
  .file-upload {
    label {
      cursor: pointer;
    }
  }
}
.cropper-container {
  max-width: calc(100% + 30px);
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
  h3 {
    margin: -0.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }
}
</style>
